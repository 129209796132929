import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TRAINING_SERVER_PREFIX}/elearning-frontend`;
const endpoints = {
    getECourseList: () => `${RESOURCE_ENDPOINT}/e-learning-course`,
    getAllECourseList: () => `${RESOURCE_ENDPOINT}/all-e-course-list`,
    getECourseCategoryList: () => `${RESOURCE_ENDPOINT}/e-course-category`,
    getECourseBannerList: () => `${RESOURCE_ENDPOINT}/e-course-banner`,
   
}

export default class FrontEndELearningCourseApi {

    public getECourseList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getECourseList();
        return HttpService.get(url, params, headers);
    }

    public getAllECourseList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAllECourseList();
        return HttpService.get(url, params, headers);
    }

    public getECourseCategoryList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getECourseCategoryList();
        return HttpService.get(url, params, headers);
    }
    public getECourseBannerList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getECourseBannerList();
        return HttpService.get(url, params, headers);
    }
  

}
