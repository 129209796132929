import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TRAINING_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getLicenseRegisterReportList: () => `${RESOURCE_ENDPOINT}/license-register-report`,
    getFyWiseTrainingYearlyPlanningReportList: () => `${RESOURCE_ENDPOINT}/fy-wise-training-yearly-plan-report`,
    getDateRangeWiseOnlineEOISubmissionReportList: () => `${RESOURCE_ENDPOINT}/date-wise-online-eoi-submission-report`,
    getFyWiseTrainingScheduleAndCirculationReportList: () => `${RESOURCE_ENDPOINT}/fy-wise-training-schedule-and-circulation-report`,
    getDateRangeWiseTrainingCirculationAndeInvitationReport: () => `${RESOURCE_ENDPOINT}/date-range-wise-training-circulation-and-e-envitation-report`,
    getTrainingCourseScheduleReportList: () => `${RESOURCE_ENDPOINT}/training-course-schedule-report`,
    getFyWiseTrainingYearlyPlanningAndImplementationSummaryReportList: () => `${RESOURCE_ENDPOINT}/fy-wise-training-yearly-plan-implementation-summary-report`,
    getMajorAchievementsInRecentYearsReportList: () => `${RESOURCE_ENDPOINT}/major-achievements-in-recent-years-report`,
    getTraineeRegistrationReport: () => `${RESOURCE_ENDPOINT}/trainee-registration-report`,
    getTraineeAttendanceReport: () => `${RESOURCE_ENDPOINT}/trainee-attendance-report`,
    getOnlineTrainingWiseParticipantResultReport: () => `${RESOURCE_ENDPOINT}/training-wise-participant-result`,
    getDateRangeWiseTraineeCertificateReport: () => `${RESOURCE_ENDPOINT}/date-range-trainee-certificate-report`,
    getDateRangeWiseSubjectWiseTrainingReport: () => `${RESOURCE_ENDPOINT}/date-range-wise-subject-wise-training-report`,
    getDateRangeWiseTrainingReport: () => `${RESOURCE_ENDPOINT}/date-range-wise-training-report`,
    getTrainerAndTraineeListWithNumberOfTrainingParticipantReport: () => `${RESOURCE_ENDPOINT}/trainer-trainee-list-with-training-participant-report`,
    getTrainerAndTraineeListWithNumberOfTrainingParticipantDetailsReport: () => `${RESOURCE_ENDPOINT}/trainer-trainee-list-with-training-participant-details-report`,
    getDateRangeWiseQuizOlympiadReport: () => `${RESOURCE_ENDPOINT}/date-range-wise-quiz-olympiad-report`,
    getTrainerCertificateAndTrainingHourReport: () => `${RESOURCE_ENDPOINT}/trainer-certificate-and-training-hour-report`,
    getOlympiadQuizResultReportList: () => `${RESOURCE_ENDPOINT}/olympiad-quiz-result-report`,
    getOlympiadQuizFinalResultReportList: () => `${RESOURCE_ENDPOINT}/olympiad-quiz-final-result-report`,
    getTrainingCourseScheduleForReportFilter: () => `${RESOURCE_ENDPOINT}/getTrainingCourseScheduleForReportFilter`,
    getExamProcessStepsForReportFilter: () => `${RESOURCE_ENDPOINT}/getExamProcessStepsForReportFilter`,

    getStaticallySummaryOfTrainingAndParticipantReportList: () => `${RESOURCE_ENDPOINT}/statically-summary-of-training-participant-report`,
    getPlannedTrainingReportList: () => `${RESOURCE_ENDPOINT}/planned-training-list-report`,
    getImplementedTrainingReportList: () => `${RESOURCE_ENDPOINT}/implemented-training-list-report`,
    getTrainingParticipantsReportList: () => `${RESOURCE_ENDPOINT}/training-participants-list-report`,
    getTrainerProfileForReportFilter: () => `${RESOURCE_ENDPOINT}/getTrainerProfileForReportFilter`,
    getDateRangeInstituteAndTrainingWiseIncomeReportList: () => `${RESOURCE_ENDPOINT}/date-range-institute-and-training-wise-income`,
    getECourseRegistrationReport: () => `${RESOURCE_ENDPOINT}/e-course-registration-report`,
}

export default class ReportTrainingApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    };

    public getLicenseRegisterReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLicenseRegisterReportList();
        return HttpService.get(url, params, headers);
    };

    public getFyWiseTrainingYearlyPlanningReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getFyWiseTrainingYearlyPlanningReportList();
        return HttpService.get(url, params, headers);
    };

    public getDateRangeWiseOnlineEOISubmissionReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseOnlineEOISubmissionReportList();
        return HttpService.get(url, params, headers);
    };

    public getFyWiseTrainingScheduleAndCirculationReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getFyWiseTrainingScheduleAndCirculationReportList();
        return HttpService.get(url, params, headers);
    };

    public getDateRangeWiseTrainingCirculationAndeInvitationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseTrainingCirculationAndeInvitationReport();
        return HttpService.get(url, params, headers);
    };

    public getTrainingCourseScheduleReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainingCourseScheduleReportList();
        return HttpService.get(url, params, headers);
    };

    public getFyWiseTrainingYearlyPlanningAndImplementationSummaryReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getFyWiseTrainingYearlyPlanningAndImplementationSummaryReportList();
        return HttpService.get(url, params, headers);
    };

    public getMajorAchievementsInRecentYearsReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getMajorAchievementsInRecentYearsReportList();
        return HttpService.get(url, params, headers);
    };

    public getTraineeRegistrationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTraineeRegistrationReport();
        return HttpService.get(url, params, headers);
    };
    public getTraineeAttendanceReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTraineeAttendanceReport();
        return HttpService.get(url, params, headers);
    };
    public getOnlineTrainingWiseParticipantResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOnlineTrainingWiseParticipantResultReport();
        return HttpService.get(url, params, headers);
    };
    public getDateRangeWiseTraineeCertificateReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseTraineeCertificateReport();
        return HttpService.get(url, params, headers);
    };
    public getDateRangeWiseSubjectWiseTrainingReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseSubjectWiseTrainingReport();
        return HttpService.get(url, params, headers);
    };
    public getDateRangeWiseTrainingReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseTrainingReport();
        return HttpService.get(url, params, headers);
    };
    public getTrainerAndTraineeListWithNumberOfTrainingParticipantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainerAndTraineeListWithNumberOfTrainingParticipantReport();
        return HttpService.get(url, params, headers);
    };
    public getTrainerAndTraineeListWithNumberOfTrainingParticipantDetailsReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainerAndTraineeListWithNumberOfTrainingParticipantDetailsReport();
        return HttpService.get(url, params, headers);
    };
    public getDateRangeWiseQuizOlympiadReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeWiseQuizOlympiadReport();
        return HttpService.get(url, params, headers);
    };
    public getTrainerCertificateAndTrainingHourReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainerCertificateAndTrainingHourReport();
        return HttpService.get(url, params, headers);
    };

    public getOlympiadQuizResultReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOlympiadQuizResultReportList();
        return HttpService.get(url, params, headers);
    };

    public getOlympiadQuizFinalResultReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOlympiadQuizFinalResultReportList();
        return HttpService.get(url, params, headers);
    };

    public getTrainingCourseScheduleForReportFilter = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainingCourseScheduleForReportFilter();
        return HttpService.get(url, params, headers);
    };

    public getExamProcessStepsForReportFilter = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExamProcessStepsForReportFilter();
        return HttpService.get(url, params, headers);
    };

    public getTrainerProfileForReportFilter = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainerProfileForReportFilter();
        return HttpService.get(url, params, headers);
    };

    public getStaticallySummaryOfTrainingAndParticipantReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getStaticallySummaryOfTrainingAndParticipantReportList();
        return HttpService.get(url, params, headers);
    };

    public getPlannedTrainingReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPlannedTrainingReportList();
        return HttpService.get(url, params, headers);
    };

    public getImplementedTrainingReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getImplementedTrainingReportList();
        return HttpService.get(url, params, headers);
    };

    public getTrainingParticipantsReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainingParticipantsReportList();
        return HttpService.get(url, params, headers);
    };

    public getDateRangeInstituteAndTrainingWiseIncomeReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateRangeInstituteAndTrainingWiseIncomeReportList();
        return HttpService.get(url, params, headers);
    };

    public getECourseRegistrationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getECourseRegistrationReport();
        return HttpService.get(url, params, headers);
    };

}
