import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TRAINING_SERVER_PREFIX}/olympiad-quiz`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    create: () => `${RESOURCE_ENDPOINT}/store`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getApplyInCourseData: () => `${RESOURCE_ENDPOINT}/get-apply-in-course-data`,
    approvedForNextPhase: () => `${RESOURCE_ENDPOINT}/approvedForNextPhase`,
    updateAssignmentMark: () => `${RESOURCE_ENDPOINT}/updateAssignmentMark`,
    customlist: () => `${RESOURCE_ENDPOINT}/customlist`,
    customlistForAssignEvaluator: () => `${RESOURCE_ENDPOINT}/customlistForAssignEvaluator`,
    customlistForAssignedParticipants: () => `${RESOURCE_ENDPOINT}/customlistForAssignedParticipants`,
    evaluatorWiseCustomList: () => `${RESOURCE_ENDPOINT}/evaluatorWiseCustomList`,
    updateStatusForEvaluationComplete: () => `${RESOURCE_ENDPOINT}/updateStatusForEvaluationComplete`,

}

export default class OlympiadQuizApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }
    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    }

    public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    }

    public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    }

    public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    }

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    }

    public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    }

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    }

    public getApplyInCourseData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getApplyInCourseData();
        return HttpService.get(url, params, headers);
    }

    public approvedSelectedParticipants = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.approvedForNextPhase();
        return HttpService.post(url, payload, params, headers);
    }

    public updateAssignmentMark = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updateAssignmentMark();
        return HttpService.post(url, payload, params, headers);
    }

    public customlist = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.customlist();
        return HttpService.get(url, params, headers);
    }

    public customlistForAssignEvaluator = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.customlistForAssignEvaluator();
        return HttpService.get(url, params, headers);
    }

    public customlistForAssignedParticipants = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.customlistForAssignedParticipants();
        return HttpService.get(url, params, headers);
    }

    public evaluatorWiseCustomList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.evaluatorWiseCustomList();
        return HttpService.get(url, params, headers);
    }

    public updateStatusForEvaluationComplete = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updateStatusForEvaluationComplete();
        return HttpService.post(url, payload, params, headers);
    }

}
