// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";
import _ExcelApi from "./Cdn/Excel.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Core/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ComponentApi from "./Core/Component.api";
import _ApplicantProfileEducationalQualificationApi from "./Core/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Core/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Core/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Core/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Core/ApplicantProfileTrainingSeminarCourse.api";
import _ExamApi from "./Core/Exam.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _PaymentApi from "./Core/Payment.api";

// Research
import _ResearchProfileApi from "./Research/ResearchProfile.api";

// Training
import _ExportTrainingApi from "./Training/ExportTraining.api";
import _ImportTrainingApi from "./Training/ImportTraining.api";
import _TrainingCategoryApi from "./Training/TrainingCategory.api";
import _TrainingSubjectApi from "./Training/TrainingSubject.api";
import _TrainingTitleApi from "./Training/TrainingTitle.api";
import _LabFacilitiesVisitAreaApi from "./Training/LabFacilitiesVisitAreaApi.api";
import _TrainerProfileApi from "./Training/TrainerProfile.api";
import _InternalTrainerUserApi from "./Training/InternalTrainerUser.api";
import _EOISubmissionApi from "./Training/EOISubmission.api"
import _YearlyTrainingPlanApi from "./Training/YearlyTrainingPlanApi.api"
import _TrainingCourseScheduleApi from "./Training/TrainingCourseScheduleApi.api"
import _TrainingCourseScheduleExamProcessApi from "./Training/TrainingCourseScheduleExamProcessApi.api"
import _RegistrationInCourseApi from "./Training/RegistrationInCourseApi.api"
import _RegistrationInECourseApi from "./Training/RegistrationInECourseApi.api"
import _RegistrationInRCOExamCircularApi from "./Training/RegistrationInRCOExamCircularApi.api"
import _EAttendanceOfTraineesApi from "./Training/EAttendanceOfTraineesApi.api"
import _TraineeExamMarksApi from "./Training/TraineeExamMarks.api"
import _LabTrainingVisitApplicationApi from "./Training/LabTrainingVisitApplicationApi.api"
import _EvaluateTrainerByTraineeApi from "./Training/EvaluateTrainerByTrainee.api"
import _OlympiadQuizApi from "./Training/OlympiadQuizApi.api"
import _ReportTrainingApi from "./Training/ReportTrainingApi.api";
import _CouponApi from "./Training/Coupon.api";
import _GlobalSettingApi from "./Training/GlobalSettingApi.api";
import _OlympiadQuizExamAndResultApi from "./Training/OlympiadQuizExamAndResultApi.api";
import _OlympiadQuizExamAnswerDetailsApi from "./Training/OlympiadQuizExamAnswerDetailsApi.api";
import _OlympiadQuizAttendanceApi from "./Training/OlympiadQuizAttendanceApi.api";
import _OlympiadQuizAssignEvaluatorApi from "./Training/OlympiadQuizAssignEvaluatorApi.api";
import _AdminDashboardApi from "./Training/AdminDashboard.api";
import _RCOExamApi from "./Training/RCOExam.api"
import _RcoExamNameApi from "./Training/RcoExamName.api"
import _CertificateTemplateApi from "./Training/CertificateTemplateApi.api";
import _CertificateTemplateVariableApi from "./Training/CertificateTemplateVariableApi.api";
import _RcoExamCircularApi from "./Training/RcoExamCircular.api";
import _CertificateApi from "./Training/CertificateApi.api";
import _RcoSetupApi from "./Training/RcoSetup.api";

//Elearning
import _CourseCategoryApi from "./Training/CourseCategory.api"
import _CourseSubCategoryApi from "./Training/CourseSubCategory.api"
import _ECourseAssesmentsApi from "./Training/ECourseAssesments.api"
import _ECourseAssesmentQuestionApi from "./Training/ECourseAssesmentQuestion.api"
import _BannerApi from "./Training/Banner.api"
import _ECourseApi from "./Training/ECourse.api"
import _ECourseAssesmentExamMarksApi from "./Training/ECourseAssesmentExamMarks.api"
import _ECourseCertificateTemplateApi from "./Training/ECourseCertificateTemplateApi.api"



//FrontEnd
import _FrontEndTrainingCourseApi from "./Training/FrontEndTrainingCourseApi.api"
import _FrontEndELearningCourseApi from "./Training/FrontEndELearningCourseApi.api"
import _InstituteApi from "./Training/Institute.api"
import _ECirculationAndInvitationApi from "./Training/ECirculationAndInvitation.api"
import _EvaluationQuestionApi from "./Training/EvaluationQuestion.api";
import _EvaluationAnswerApi from "./Training/EvaluationAnswer.api";
import _TrainerEvaluationSetupApi from "./Training/TrainerEvaluationSetup.api";
import _TrainerEvaluationQuestionApi from "./Training/TrainerEvaluationQuestion.api"
import _TraineeQuizApi from "./Training/TraineeQuiz.api"
import _TraineeQuizQuestionApi from "./Training/TraineeQuizQuestion.api"


// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _WebsocketApi from "./Util/Websocket.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const ExcelApi = new _ExcelApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ComponentApi = new _ComponentApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ExamApi = new _ExamApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const PaymentApi = new _PaymentApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const WebsocketApi = new _WebsocketApi();

// Researcher Profile
export const ResearchProfileApi = new _ResearchProfileApi();

// Training
export const ExportTrainingApi = new _ExportTrainingApi();
export const ImportTrainingApi = new _ImportTrainingApi();
export const TrainingCategoryApi = new _TrainingCategoryApi();
export const TrainingSubjectApi = new _TrainingSubjectApi();
export const TrainingTitleApi = new _TrainingTitleApi();
export const LabFacilitiesVisitAreaApi = new _LabFacilitiesVisitAreaApi();
export const TrainerProfileApi = new _TrainerProfileApi();
export const InternalTrainerUserApi = new _InternalTrainerUserApi();
export const EOISubmissionApi = new _EOISubmissionApi();
export const YearlyTrainingPlanApi = new _YearlyTrainingPlanApi();
export const TrainingCourseScheduleApi = new _TrainingCourseScheduleApi();
export const TrainingCourseScheduleExamProcessApi = new _TrainingCourseScheduleExamProcessApi();
export const RegistrationInCourseApi = new _RegistrationInCourseApi();
export const RegistrationInECourseApi = new _RegistrationInECourseApi();
export const RegistrationInRCOExamCircularApi = new _RegistrationInRCOExamCircularApi();
export const EAttendanceOfTraineesApi = new _EAttendanceOfTraineesApi();
export const TraineeExamMarksApi = new _TraineeExamMarksApi();
export const LabTrainingVisitApplicationApi = new _LabTrainingVisitApplicationApi();
export const EvaluateTrainerByTraineeApi = new _EvaluateTrainerByTraineeApi();
export const OlympiadQuizApi = new _OlympiadQuizApi();
export const ReportTrainingApi = new _ReportTrainingApi();
export const CouponApi = new _CouponApi();
export const GlobalSettingApi = new _GlobalSettingApi();
export const OlympiadQuizExamAndResultApi = new _OlympiadQuizExamAndResultApi();
export const OlympiadQuizExamAnswerDetailsApi = new _OlympiadQuizExamAnswerDetailsApi();
export const OlympiadQuizAttendanceApi = new _OlympiadQuizAttendanceApi();
export const OlympiadQuizAssignEvaluatorApi = new _OlympiadQuizAssignEvaluatorApi();
export const AdminDashboardApi = new _AdminDashboardApi();
export const RCOExamApi = new _RCOExamApi();
export const RcoExamNameApi = new _RcoExamNameApi();
export const CertificateTemplateApi = new _CertificateTemplateApi();
export const CertificateTemplateVariableApi = new _CertificateTemplateVariableApi();
export const RcoExamCircularApi = new _RcoExamCircularApi();
export const CertificateApi = new _CertificateApi();
export const RcoSetupApi = new _RcoSetupApi();

//ELearning
export const CourseCategoryApi = new _CourseCategoryApi();
export const CourseSubCategoryApi = new _CourseSubCategoryApi();
export const ECourseAssesmentsApi = new _ECourseAssesmentsApi();
export const ECourseAssesmentQuestionApi = new _ECourseAssesmentQuestionApi();
export const BannerApi = new _BannerApi();
export const ECourseApi = new _ECourseApi();
export const ECourseAssesmentExamMarksApi = new _ECourseAssesmentExamMarksApi();
export const ECourseCertificateTemplateApi = new _ECourseCertificateTemplateApi();

//FrontEnd
export const FrontEndTrainingCourseApi = new _FrontEndTrainingCourseApi();
export const FrontEndELearningCourseApi = new _FrontEndELearningCourseApi();

export const InstituteApi = new _InstituteApi();
export const ECirculationAndInvitationApi = new _ECirculationAndInvitationApi();
export const EvaluationQuestionApi = new _EvaluationQuestionApi();
export const EvaluationAnswerApi = new _EvaluationAnswerApi();
export const TrainerEvaluationSetupApi = new _TrainerEvaluationSetupApi();
export const TrainerEvaluationQuestionApi = new _TrainerEvaluationQuestionApi();
export const TraineeQuizApi = new _TraineeQuizApi();
export const TraineeQuizQuestionApi = new _TraineeQuizQuestionApi();
