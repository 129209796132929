import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TRAINING_SERVER_PREFIX}/frontend`;
const endpoints = {
    getTrainingCategoryList: () => `${RESOURCE_ENDPOINT}/training-category`,
    getAllCourseList: () => `${RESOURCE_ENDPOINT}/all-course-list`,
    getOnGoingTrainingList: () => `${RESOURCE_ENDPOINT}/on-going-training`,
    getUpCommingTrainingList: () => `${RESOURCE_ENDPOINT}/up-comming-training`,
    getRecentlyCompletedCourseList: () => `${RESOURCE_ENDPOINT}/recently-completed-courses`,
    getRcoExamCircularList: () => `${RESOURCE_ENDPOINT}/rco-exam-circular-list`,
}

export default class FrontEndTrainingCourseApi {

    public getTrainingCategoryList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTrainingCategoryList();
        return HttpService.get(url, params, headers);
    }

    public getAllCourseList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAllCourseList();
        return HttpService.get(url, params, headers);
    }

    public getOnGoingTrainingList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOnGoingTrainingList();
        return HttpService.get(url, params, headers);
    }

    public getUpCommingTrainingList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getUpCommingTrainingList();
        return HttpService.get(url, params, headers);
    }

    public getRecentlyCompletedCourseList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRecentlyCompletedCourseList();
        return HttpService.get(url, params, headers);
    }
    public getRcoExamCircularList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRcoExamCircularList();
        return HttpService.get(url, params, headers);
    }


}
