export const dateFormat = 'YYYY-MM-DD';
export const DATE_FORMAT_DATABASE = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_DATABASE = 'YYYY-MM-DD HH:mm:ss';
export const YEAR_FORMAT_DATABASE = 'YYYY';
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DATE_PICKER_DATE_FORMAT = 'DD-MMM-YYYY'
export const DATE_PICKER_DATE_FORMAT_A = 'DD-MM-YYYY'
export const DATE_PICKER_DATE_TIME_FORMAT = 'DD-MMM-YYYY h:mm a'
// export const DATE_PICKER_DATE_TIME_FORMAT_B = 'DD-MM-YYYY hh:mm:ss A'
export const DATE_PICKER_DATE_TIME_FORMAT_B = 'YYYY-MM-DD h:mm:s A'
export const DATE_PICKER_MONTH_FORMAT = 'YYYY-MM'
export const DATE_PICKER_MONTH_FORMAT_A = 'MMMM-YYYY'
export const DATE_PICKER_TIME_FORMAT = 'HH:mm:ss'
export const DATE_PICKER_YEAR_FORMAT = 'YYYY'
export const DATE_PICKER_MONTH_YEAR_FORMAT = 'MMM-YYYY'

// Timers
export const INTERVAL_ONE_SECOND = 1000;
export const INTERVAL_TEN_SECONDS = (10 * 1000);
export const INTERVAL_THIRTY_SECONDS = (30 * 1000);

export const INTERVAL_ONE_MINUTE = (60 * 1000);
export const INTERVAL_TWO_MINUTES = (2 * 60 * 1000);
export const INTERVAL_FIVE_MINUTES = (5 * 60 * 1000);

export const INTERVAL_ONE_HOUR = (60 * 60 * 1000);
export const INTERVAL_ONE_DAY = (24 * 60 * 60 * 1000);

// Token Refresh
export const TOKEN_REFRESH_INTERVAL = (10 * 60 * 1000);
export const GRANT_COMPONENT_KEY = 'GRANT';

// Service key
export const BSTFT_SRVICE_KEY = 'BST_FELLOWSHIP';
export const RnD_SRVICE_KEY = 'GRANTS_APPLICATION_R_AND_D_PROJECT';
export const NST_SRVICE_KEY = 'NST_FELLOWSHIP';
export const SPECIAL_RESEARCH_GRANT_SRVICE_KEY = 'SPECIAL_RESEARCH_GRANT';
export const NON_GOVT_INSTITUTE_KEY = 'NON_GOVT_INSTITUTE';

// Exam Group Key
export const EXAM_GROUP_SSC = 'SECONDARY';
export const EXAM_GROUP_HSC = 'HIGHER_SECONDARY';
export const EXAM_GROUP_GRADUATION = 'HONOURS';
export const EXAM_GROUP_MS = 'MASTERS';


// Organization Short code
export const MoST_CODE = 'MOST';
